const hiddenClass = "hidden";
const hideElement = element => element.classList.add(hiddenClass);
const showElement = element => element.classList.remove(hiddenClass);

const selectedClass = "selected";
const selectElement = element => element.classList.add(selectedClass);
const unselectElement = element => element.classList.remove(selectedClass);

const getToolTabs = () => document.getElementsByClassName("tool-tab");
const getToolGroups = () => document.getElementsByClassName("tool-group");

const hideAllToolGroups = () => {
    const toolGroups = getToolGroups();
    for (let i = 0; i < toolGroups.length; i++) {
        hideElement(toolGroups[i]);
    }
};

const deselectAllToolTabs = () => {
    const toolTabs = getToolTabs();
    for (let i = 0; i < toolTabs.length; i++) {
        unselectElement(toolTabs[i]);
    }
};

const showToolGroup = (event, toolGroup) => {
    hideAllToolGroups();
    deselectAllToolTabs();
    showElement(document.getElementById(toolGroup));
    selectElement(event.currentTarget);
};

window.onload = () => {
    hideAllToolGroups();
    deselectAllToolTabs();
    showElement(getToolGroups()[0]);
    selectElement(getToolTabs()[0]);
};
